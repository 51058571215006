<template>
  <div v-once class="gridbox-element" :style="`order:${index * 100}`">
    <v-hover v-slot="{ isHovering, props }" :disabled="!hover|| resolution.width <= resolution.tablet">
      <v-card
        v-bind="props"
        :bg="bg"
        :style="split && resolution.width > resolution.mobile ? `box-shadow:none;` : ``"
        :pd="split && resolution.width > resolution.tablet && resolution.width < resolution.notebook ? `3xl` : ``"
        :bshadow="rule === 'wide-card' && resolution.width <= resolution.mobile ? `unset` : ``"
      >

        <!-- 레이어 -->
        <NuxtLink
          v-if="resolution.width > resolution.tablet"
          :to="`/game/melody/${item.id}`"
          :title="`${item.title} ${$t('game-start')}`"
        >
          <v-overlay
            :model-value="isHovering"
            contained
            scrim="secondary"
            class="align-center justify-center"
          >
            <div class="box" gap="xs">
              <v-btn variant="flat" color="primary" pd="lg" block :title="$t('game-start')">
                <v-icon icon="mdi-music" mgr="2xs" />
                <span></span>{{ $t('game-start') }}
              </v-btn>
              <v-btn :to="`/game/melody/statistics/${item.id}`" :title="$t('game-statistics')" variant="flat" bg="xs" pd="lg" block>
                <v-icon icon="mdi-chart-timeline-variant" mgr="2xs" />
                {{ $t('game-statistics') }}
              </v-btn>
              <v-btn
                v-if="user.login && user.id === item.user.id"
                :to="`/game/melody/modify?id=${item.id}`"
                :title="$t('form-modify-btn')"
                icon="mdi-pencil"
                variant="tonal"
                size="small"
              >
              </v-btn>
            </div>
          </v-overlay>
        </NuxtLink>

        <!--------------------------------------------------------------------------------------------------------------
          와이드 카드형
        --------------------------------------------------------------------------------------------------------------->
        <div v-if="rule === 'wide-card'"
          :pd="resolution.width > resolution.tablet ? `2xl` : resolution.width > resolution.mobile ? `lg` : ``"
          :gap="resolution.width > resolution.tablet ? `2xl` : resolution.width > resolution.mobile ? `lg` : `xs`"
          :bg="resolution.width > resolution.mobile ? bg : `default`"
          class="box"
          media="mobile"
        >
          <NuxtLink v-if="resolution.width > resolution.mobile" :to="`/game/melody/${item.id}`" :title="`${item.title} ${$t('game-start')}`">
            <figure>
              <Image
                :image="item.thumbnail"
                :ratio="16/9"
                :radius="`3xs`"
                :width="resolution.width > resolution.mobile ? 200 : 0"
                :country="item.country"
                :alt="item.title"
                bg="3xs"
              />
            </figure>
          </NuxtLink>
          <div class="box" direction="column">
            <p v-if="resolution.width > resolution.mobile" :break="resolution.width > resolution.tablet ? `ellipsis-1` : `ellipsis-2`">
              <strong fsize="lg">
                {{ item.title }}
              </strong>
            </p>
            <div v-if="resolution.width <= resolution.mobile">
              <div class="box" gap="lg">
                <NuxtLink :to="`/game/melody/${item.id}`" :title="`${item.title} ${$t('game-start')}`">
                  <figure>
                    <Image
                      :image="item.thumbnail"
                      :ratio="16/9"
                      :radius="`3xs`"
                      :width="130"
                      :country="item.country"
                      :alt="item.title"
                      mgt="3xs"
                      bg="3xs"
                    />
                  </figure>
                </NuxtLink>
                <div class="box" direction="column">
                  <Ellipsis
                    :text="item.title"
                    :clamp="1"
                    mgb="3xs"
                  />
                  <Ellipsis
                    v-if="item.description"
                    :text="item.description"
                    :clamp="2"
                    :more="true"
                    :fsize="`sm`"
                    :color="`2xl`"
                    :position="`start`"
                    mgb="3xs"
                  />
                </div>
              </div>
              <hr class="hr" mgt="2xs" mgb="xs" bg="op-5xs-white">
            </div>
            <div pdt="3xs" pdb="sm" fsize="sm" color="md">
              <Ellipsis
                :text="item.description || $t('create-game-description-empty')"
                :clamp="1"
              />
            </div>
            <component
              :is="MelodyItemListStatistics"
              :game="item"
            />
            <!-- <p v-if="resolution.width > resolution.tablet" mgt="2xs" fsize="xs" color="md">
              <span pdr="2xs">
                {{ $t('game-reddate') }}
              </span>
              <Date :date="item.regdate" />
            </p> -->
          </div>
          <div mgl="auto">
            <v-btn
              :to="`/game/melody/${item.id}`"
              :title="$t('game-start')"
              size="large"
              color="secondary"
              variant="outlined"
              block
              rounded
            >
              <v-icon icon="mdi-music" mgr="2xs" />
              {{ $t('game-start') }}
            </v-btn>
          </div>
        </div>

        <!--------------------------------------------------------------------------------------------------------------
          마이 레코드 카드형
        --------------------------------------------------------------------------------------------------------------->
        <div v-else-if="rule === 'me-records' && resolution.width > resolution.mobile" class="box" pd="2xl" :bg="bg" :bghover="bghover" gap="xl">
          <div>
            <NuxtLink :to="`/game/melody/${item.id}`" :title="`${item.title} ${$t('game-start')}`" media="mobile order-1">
              <Image
                :image="item.thumbnail"
                :ratio="16/9"
                :radius="`3xs`"
                :width="resolution.width > resolution.mobile ? 140 : 0"
                :country="item.country"
                :alt="item.title"
              />
            </NuxtLink>
            <v-btn
              mgt="xs"
              variant="tonal"
              bg="3xs"
              halign="center"
              size="small"
              extent="wm"
              :title="`${item.title} ${$t('game-start')}`"
            >
              <v-icon icon="mdi-music" mgr="xs" />
              <span break="ellipsis-1">
                {{ $t('game-restart') }}
              </span>
            </v-btn>
          </div>
          <div flex="1" class="box" direction="column" media="mobile order-3">
            <div pdb="sm" :break="resolution.width > resolution.tablet ? `ellipsis-1` : `ellipsis-2`">
              {{ item.title }}
              <NuxtLink :to="`/game/melody/statistics/${item.id}/comment`" :title="`${$t('game-melody-comment-shorts')} ${item.commentCounts}`">
                <v-badge
                  v-if="item.commentCounts"
                  :content="`${$t('game-melody-comment-shorts')} ${item.commentCounts}`"
                  color="green-darken-4"
                  inline
                />
              </NuxtLink>
            </div>
            <component
              :is="MelodyItemListStatistics"
              :game="item"
            />
            <v-chip-group v-if="item?.hash_tag?.length" class="pb-0">
              <NuxtLink :to="`/?q=${tag}`" v-for="(tag, hashTag_index) in item?.hash_tag" :key="hashTag_index" :title="`${$t('hashtag')}: ${tag}`">
                <v-chip bg="2xs">
                  <span fsize="xs">
                    # {{ tag }}
                  </span>
                </v-chip>
              </NuxtLink>
            </v-chip-group>
            <hr class="hr" mgt="2xs" mgb="xs" bg="op-5xs-white">
            <Ellipsis
              v-if="item.description"
              :text="item.description"
              :clamp="2"
              :more="true"
              :fsize="`sm`"
              :color="`2xl`"
              :position="`start`"
              mgb="3xs"
            />
            <p fsize="xs" color="sm">
              <Date :date="item.regdate" />
            </p>
          </div>
          <div class="box" direction="column" halign="between" gap="lg" mgl="auto" media="mobile order-2">
            <v-progress-circular
              :model-value="item.my_statistics?.score"
              :size="140"
              :width="1"
              :color="globalFunctions.getScoreColor(item.my_statistics?.score)"
              mgl="auto"
              mgr="auto"
            >
              <div talign="center">
                <p>
                  {{ $t('game-score') }}
                </p>
                <strong fsize="3xl" weight="900">
                  {{ item.my_statistics?.score }}
                </strong>
              </div>
            </v-progress-circular>
            <v-btn
              :to="`/game/melody/statistics/${item.id}`"
              :title="$t('game-detail-records')"
              :color="globalFunctions.getScoreColor(item.my_statistics?.score)"
              variant="outlined"
              bg="default"
              halign="center"
              size="small"
              extent="wm"
            >
              <v-icon icon="mdi-chart-box" mgr="xs" />
              <strong break="ellipsis-1">
                {{ $t('game-detail-records') }}
              </strong>
            </v-btn>
          </div>
        </div>

        <!--------------------------------------------------------------------------------------------------------------
          마이 레코드 모바일 카드형
        --------------------------------------------------------------------------------------------------------------->
        <div v-else-if="rule === 'me-records' && resolution.width <= resolution.mobile"
          class="box"
          :direction="resolution.width > resolution.mobile ? `` : `column`"
          :pd="resolution.width > resolution.mobile ? `lg` : ``"
          :gap="resolution.width > resolution.mobile ? `3xl` : ``"
          :bg="bg"
        >
          <div :pd="resolution.width > resolution.mobile ? `xl` : `sm`" class="box" direction="column">
            <div class="box" gap="lg">
              <NuxtLink :to="`/game/melody/${item.id}`" :title="`${item.title} ${$t('game-start')}`">
                <Image
                  :image="item.thumbnail"
                  :width="108"
                  :ratio="16/9"
                  :radius="`3xs`"
                  :country="item.country"
                  :alt="item.title"
                />
              </NuxtLink>
              <div>
                <p pdb="3xs" :break="resolution.width > resolution.tablet ? `ellipsis-1` : `ellipsis-2`">
                  {{ item.title }}
                </p>
                <Ellipsis
                  v-if="item.description"
                  :text="item.description"
                  :clamp="2"
                  :fsize="`sm`"
                  :color="`2xl`"
                  :position="`start`"
                  mgb="3xs"
                />
                <p fsize="xs" color="sm">
                  <Date :date="item.regdate" />
                </p>
              </div>
            </div>
            <hr class="hr" mg="md-y" bg="3xs">
            <component
              :is="MelodyItemListStatistics"
              :game="item"
            />
            <v-chip-group v-if="item?.hash_tag?.length" class="pb-0">
              <NuxtLink :to="`/?q=${tag}`" v-for="(tag, hashTag_index) in item?.hash_tag" :key="hashTag_index" :title="`${$t('hashtag')}: ${tag}`">
                <v-chip bg="2xs">
                  <span fsize="xs">
                    # {{ tag }}
                  </span>
                </v-chip>
              </NuxtLink>
            </v-chip-group>
            <hr class="hr" mg="md-y" bg="3xs">
            <div class="box" gap="xs" valign="end">
              <div>
                <p fsize="xs" color="md">
                  <span fsize="sm" color="secondary" pdr="2xs">
                    {{ $t('game-score') }}
                  </span>
                  <Date :date="item.my_statistics?.regdate" :type="`YYYYMMDD`" />
                </p>
                <div class="box" gap="xs" valign="center" halign="between" fsize="2xl" break="nowrap" lh="lg">
                  <span :class="`box text-${globalFunctions.getScoreColor(item.my_statistics?.score)}`" weight="900" valign="center" gap="xs">
                    {{ item.my_statistics?.score }}
                  </span>
                </div>
              </div>
              <span mgl="auto">
                <v-btn
                  :to="`/game/melody/statistics/${item.id}`"
                  :color="globalFunctions.getScoreColor(item.my_statistics?.score)"
                  :title="$t('game-detail-records')"
                  mgl="auto"
                  mgb="4xs"
                  variant="outlined"
                  halign="start"
                  pd="md"
                  size="small"
                  block
                >
                  <v-icon icon="mdi-chart-box" mgr="xs" />
                  <span break="ellipsis-1">
                    {{ $t('game-detail-records') }}
                  </span>
                </v-btn>
              </span>
            </div>
          </div>
        </div>

        <!--------------------------------------------------------------------------------------------------------------
          기본
        --------------------------------------------------------------------------------------------------------------->
        <div v-else :class="split && resolution.width > resolution.tablet ? `box` : ``" :gap="split && resolution.width > resolution.tablet ? `lg` : ``" valign="start">
          <NuxtLink :to="`/game/melody/${item.id}`" :title="`${item.title} ${$t('game-start')}`" flex="1" :class="split && resolution.width > resolution.tablet ? `box` : ``" pos="relative" valign="start">
            <Image
              :id="item.id"
              :image="item.thumbnail"
              :ratio="16/9"
              :radius="`3xs`"
              :country="item.country"
              :alt="item.title"
              :bg="`4xs`"
            />
          </NuxtLink>
          <span v-if="split && resolution.width > resolution.tablet" extent="w-1" bg="3xs" />
          <div flex="1" class="box" direction="column" :tpd="resolution.width > resolution.tablet ? `${split ? `` : `2xl`}` : `xl`">
            <p v-if="item.year[0]" color="secondary" pdb="4xs" fsize="xs" weight="700">
              [ 📆 
              <template v-for="(year, year_index) in item.year" :key="year_index">
                <span v-if="year_index">
                  ~
                </span>
                {{ year }}
              </template>
              ]
            </p>
            <p :pdb="resolution.width > resolution.tablet ? `md` : `md`">
              <strong :fsize="split && resolution.width > resolution.tablet ? `xl` : `md`">
                {{ item.title }}
              </strong>
            </p>
            <component
              :is="MelodyItemListStatistics"
              :game="item"
            />
            <div>
              <Ellipsis
                v-if="split" mgt="sm"
                :text="item.description || $t('create-game-description-empty')"
                :clamp="3"
                :more="true"
                :fsize="`sm`"
                :color="`md`"
                :position="`start`"
              />
              <div v-else break="ellipsis-2" fsize="sm" color="md" mgt="xs" v-html="item.description || $t('create-game-description-empty')" />
              <div v-if="item.hash_tag?.length" class="box" gap="3xs" mgt="xs" break="wrap">
                <NuxtLink :to="`/?q=${tag}`" v-for="(tag, tag_index) in item.hash_tag" :key="tag_index" :title="`${$t('hashtag')}: ${tag}`">
                  <v-chip fsize="2xs" bg="2xs" size="small">
                    # {{ tag }}
                  </v-chip>
                </NuxtLink>
              </div>
              <!-- <p pdt="xs" fsize="xs" color="md">
                {{ $t('game-reddate') }}
                <Date :date="item.regdate" color="3xl" mgl="2xs" />
              </p> -->
            </div>
            <div mgt="md" v-if="split && resolution.width > resolution.tablet">
              <v-btn
                :to="`/game/melody/${item.id}`"
                :title="`${item.title} ${$t('game-start')}`"
                flex="1"
                class="box"
                size="large"
                color="secondary"
                variant="outlined"
                block
                rounded
              >
                <v-icon icon="mdi-music" mgr="2xs" />
                {{ $t('game-start') }}
              </v-btn>
            </div>
          </div>
        </div>

      </v-card>
    </v-hover>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
import { useGlobalStore } from '~/store/global'
// import MelodyItemListStatistics from '~/component/game/melody/list-statistics.vue'

export default {
  async setup () {

    // 글로벌 스토어
    const { user } = storeToRefs(useAuthStore())
    const { resolution } = storeToRefs(useGlobalStore())

    // 메모리 비우기
    function restore () {
      MelodyItemListStatistics.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const MelodyItemListStatistics: any = shallowRef(null)
    const loadComponent = async () => {
      const MelodyItemListStatistics_load = await import('~/component/game/melody/list-statistics.vue')
      MelodyItemListStatistics.value = MelodyItemListStatistics_load.default || MelodyItemListStatistics_load
    }
    loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      user,
      resolution,

      // 컴포넌트
      MelodyItemListStatistics
    }
  },
  props: {
    index: {
      type: Number,
      default () {
        return 0
      }
    } as any,
    item: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    } as any,
    rule: {
      type: String,
      default () {
        return ''
      }
    } as any,
    bg: {
      type: String,
      default () {
        return ''
      }
    },
    bghover: {
      type: String,
      default () {
        return ''
      }
    },
    hover: {
      type: Boolean,
      default () {
        return true
      }
    },
    split: {
      type: Boolean,
      default () {
        return false
      }
    }
  }
}
</script>